<template>
  <div class="home_box">
    <div class="header_box">
      <img class="title_img" src="./images/title@2x.png" alt="" />
      <div class="date flex items-center">
        <div class="week">
          <!-- <n-gradient-text
            size="16"
            :gradient="{
              from: '#fff',
              to: '#F8EEC6',
            }"
          >
          
          </n-gradient-text> -->
          <div>{{ dateStr }}</div>
        </div>
        <div class="ml-4">
          <!-- <n-gradient-text
            size="20"
            :gradient="{
              from: '#fff',
              to: '#F8EEC6',
            }"
          >
            
          </n-gradient-text> -->
          <div>{{ timeStr }}</div>
        </div>
        <div class="ml-4 weather flex items-end" v-if="weatherData.loaded">
          <div class="icon">
            <i :class="['qi-' + weatherData.icon]"></i>
          </div>
          <div class="txt ml-2"></div>
          {{ weatherData.text }} {{ weatherData.temp }}～{{ weatherData.feelsLike }}℃
          {{ weatherData.windDir }}{{ weatherData.windScale }}级
        </div>
      </div>
      <img src="./images/logo.png" class="logo" alt="" />
    </div>
    <div class="home_cont flex justify-between">
      <div class="home_cont-side">
        <div class="operate-status">
          <Container title="收益总览">
            <div class="select-box flex">
              <!-- <n-select
                style="width: 60px; margin: 0 6px 0 0"
                size="tiny"
                v-model:value="profitOverview.type"
                :options="options"
                @update:value="changeDatePickerType"
              ></n-select>
              <n-date-picker
                style="width: 210px"
                v-model:value="profitOverview.dateSelect"
                :type="datePickerType"
                @update:value="changeDatePicker"
                clearable
                size="tiny"
              /> -->
              <el-select
                class="type-select"
                v-model="profitOverview.type"
                size="mini"
                style="width: 60px; margin: 0 2px 0 0"
                @change="changeDatePickerType"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-date-picker
                class="date-select"
                v-model="profitOverview.dateSelect"
                style="width: 180px"
                :type="profitOverview.type === 0 ? 'daterange' : 'month'"
                range-separator="至"
                start-placeholder="开始日期"
                value-format="timestamp"
                end-placeholder="结束日期"
                @change="changeDatePicker"
                :clearable="false"
                size="mini"
              >
              </el-date-picker>
            </div>
            <div class="h-full flex flex-col justify-center" v-loading="profitOverview.loading">
              <div class="icon-box flex">
                <img class="icon-img" src="./images/run.png" alt="" />
                <div class="icon-cont flex flex-col justify-around">
                  <div class="icon-title">
                    <!-- <n-number-animation
                      show-separator
                      :from="0"
                      :to="profitOverview.electric_benefit"
                      :precision="2"
                  /> -->
                    {{ keepDecimal(profitOverview.electric_benefit) }}
                  </div>
                  <div class="icon-sub">总收益(元)</div>
                </div>
              </div>

              <div class="run-line flex">
                <div class="run-item">储能放电量</div>
                <div class="run-item text-right"
                  ><span class="strong">{{
                    keepDecimal(profitOverview.total_discharge_quantity)
                  }}</span>
                  kWH</div
                >
                <div class="run-item">储能放电收益</div>
                <div class="run-item text-right"
                  ><span class="strong">{{
                    keepDecimal(profitOverview.total_discharge_benefit)
                  }}</span>
                  元</div
                >
              </div>
              <div class="run-line flex">
                <div class="run-item">储能充电量</div>
                <div class="run-item text-right"
                  ><span class="strong">{{
                    keepDecimal(profitOverview.total_charge_quantity)
                  }}</span>
                  kWH</div
                >
                <div class="run-item">储能充电成本</div>
                <div class="run-item text-right"
                  ><span class="strong">{{ keepDecimal(profitOverview.total_charge_cost) }}</span>
                  元</div
                >
              </div>
              <div class="run-line flex">
                <div class="run-item">光伏发电量</div>
                <div class="run-item text-right"
                  ><span class="strong">{{
                    keepDecimal(profitOverview.pv_discharge_quantity)
                  }}</span>
                  kWH</div
                >
                <div class="run-item">光伏发电收益</div>
                <div class="run-item text-right"
                  ><span class="strong">{{
                    keepDecimal(profitOverview.pv_discharge_benefit)
                  }}</span>
                  元</div
                >
              </div>
            </div>
          </Container>
        </div>
        <div class="total-load margin-top">
          <Container title="光伏发电情况">
            <div class="flex h-full">
              <div class="w-1/2 icon-box flex items-center small">
                <img class="icon-img" src="./images/icon2@2x.png" alt="" />
                <div class="icon-cont flex flex-col justify-between">
                  <div class="icon-title">
                    <!-- <n-number-animation
                      show-separator
                      :from="0"
                      :to=""
                      :precision="2"
                    /> -->
                    {{ keepDecimal(photovoltaicsStatus.today_pv_discharge_quantity) }}
                  </div>
                  <div class="icon-sub">当日发电量(KWH)</div>
                </div>
              </div>
              <div class="w-1/2 icon-box flex items-center small">
                <img class="icon-img" src="./images/icon3@2x.png" alt="" />
                <div class="icon-cont flex flex-col justify-between">
                  <div class="icon-title">
                    <!-- <n-number-animation
                      show-separator
                      :from="0"
                      :to=""
                      :precision="2"
                    /> -->
                    {{ keepDecimal(photovoltaicsStatus.month_pv_discharge_quantity) }}
                  </div>
                  <div class="icon-sub">当月发电量(KWH)</div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div class="photovoltaics margin-top">
          <Container title="光伏发电曲线">
            <div class="charts-dom" ref="photovoltaics"></div>
          </Container>
        </div>

        <!-- <div class="carbon-reduction margin-top">
          <Container title="当前年度减碳量">
            <div v-loading="totalReduceCarbon.loading" style="height: 100%">
              <div class="amount flex items-center">
                <img class="amount-icon" src="./images/carbon-reduction-amount.png" alt="" />
                <div class="amount-title">
                  {{ keepDecimal(totalReduceCarbon.amount) }}
                </div>
                <div class="amount-sub">kgCO2</div>
              </div>
              <div class="charts-dom" ref="carbonReduction"></div>
            </div>
          </Container>
        </div> -->
      </div>
      <div class="home_cont-center">
        <div class="flex justify-center measure">
          <div class="measure-item">
            <div class="measure-title">光伏累计发电量(KWH)</div>
            <Number :value="photovoltaicsStatus.total_pv_discharge_quantity"></Number>
          </div>
          <div class="measure-item">
            <div class="measure-title">累计碳减排量(kgCO2)</div>
            <Number :value="photovoltaicsStatus.total_reduce_co2"></Number>
          </div>
        </div>
        <img src="./images/building.png" class="building" alt="" />
        <div class="tree">
          <div class="left">
            <div class="title">
              {{ keepDecimal(photovoltaicsStatus.total_pv_discharge_quantity) }}
            </div>
            <div class="sub">光伏累计发电量<br />（KWH）</div>
          </div>
          <div class="right">
            <div class="title">
              {{ keepDecimal(photovoltaicsStatus.total_reduce_co2) }}
            </div>
            <div class="sub">累计碳减排量<br />（kgCO2）</div>
          </div>

          <div class="bubble bubble1" ref="bubble1"></div>
          <div class="bubble bubble2" ref="bubble2"></div>
          <div class="bubble bubble3" ref="bubble3"></div>
          <div class="bubble bubble4" ref="bubble4"></div>
          <div class="bubble bubble5" ref="bubble5"></div>
          <div class="bubble bubble6" ref="bubble6"></div>
          <div class="bubble bubble7" ref="bubble7"></div>
        </div>
      </div>
      <div class="home_cont-side">
        <div class="run-time">
          <Container title="系统运行时间">
            <div class="flex">
              <div class="w-1/2 icon-box flex items-center small">
                <img class="icon-img" src="./images/icon4@2x.png" alt="" />
                <div class="icon-cont flex flex-col justify-between">
                  <div class="icon-title">
                    {{ keepDecimal(runTime.hour) }}
                  </div>
                  <div class="icon-sub">累计运行时长(H)</div>
                </div>
              </div>
              <div class="w-1/2 icon-box flex items-center small">
                <img class="icon-img" src="./images/icon5@2x.png" alt="" />
                <div class="icon-cont flex flex-col justify-between">
                  <div class="icon-title">
                    {{ keepDecimal(runTime.day) }}
                  </div>
                  <div class="icon-sub">安全运行天数(日)</div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div class="charge-discharge margin-top">
          <Container title="储能充放电功率曲线">
            <div class="select-box flex">
              <Legend class="mr-4" title="放电功率" color="#05AC99"></Legend>
              <Legend title="充电功率" color="#EEE1AD"></Legend>
            </div>
            <div class="charts-dom" ref="chargeDischargePower"></div>
          </Container>
        </div>

        <div class="peak-valley margin-top">
          <Container title="峰谷电充放电量对比(7日)">
            <div class="select-box">
              <div class="tabs flex">
                <div
                  :class="['tab-item', peakValleyData.active === 0 ? 'active' : '']"
                  @click="clickChargeDischarge(0)"
                  >充电量</div
                >
                <div
                  :class="['tab-item', peakValleyData.active === 1 ? 'active' : '']"
                  @click="clickChargeDischarge(1)"
                  >放电量</div
                >
              </div>
            </div>
            <div class="amount flex items-center">
              <img class="amount-icon" src="./images/vally.png" alt="" />
              <div class="amount-title">
                {{ keepDecimal(peakValleyData.amount) }}
              </div>
              <div class="amount-sub">KWH</div>
            </div>
            <div class="charts-dom" ref="peakValley"></div>
          </Container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // 55寸屏幕：1538 * 872 px
  import { getRandomInt, isArray, isNumber } from '@/utils';
  import gsap from 'gsap';
  import moment from 'moment';

  import Container from '@/components/Container';
  import Legend from '@/components/Legend';
  import Number from '@/components/Number';

  import * as echarts from 'echarts';

  import {
    fetchTotalProfit,
    fetchOperateProfit,
    fetchProjectList,
    fetchCarbonRealtime,
    fetchPVPower,
    fetchPVRate,
    fetchYearReduceCarbon,
    fetchReportMonth,
    fetchChargeRate,
    fetchChargeOneRate,
    fetchBatteryProfit,
    fetchWeather,
  } from '@/api/home';

  const dayEnum = {
    0: '日',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
  };

  // 峰谷电充放电量对比(7日)
  // 缓存数据
  const _cost = {};
  const _total = {};

  gsap.registerEffect({
    name: 'bubble',
    extendTimeline: true,
    effect: (targets, config) => {
      const {
        range = 8,
        top = 10,
        duration = 4,
        startOpacity = 1,
        opacity = 0.2,
        delay = 0.2,
      } = config;

      const perOpacity = (startOpacity - opacity) / 4;

      const tl = gsap.timeline({
        repeat: -1,
        delay,
      });
      tl.to(targets[0], {
        opacity: startOpacity,
        duration: 0.6,
      })
        .to(targets[0], {
          x: -range / 2,
          y: -top / 4,
          opacity: startOpacity - perOpacity,
          duration: duration / 4,
          ease: 'none',
        })
        .to(targets[0], {
          x: 0,
          y: -top / 2,
          opacity: startOpacity - perOpacity * 2,
          duration: duration / 4,
          ease: 'none',
        })
        .to(targets[0], {
          x: range / 2,
          y: -top * 0.75,
          opacity: startOpacity - perOpacity * 3,
          duration: duration / 4,
          ease: 'none',
        })
        .to(targets[0], {
          x: 0,
          y: -top,
          opacity: opacity,
          duration: duration / 4,
          ease: 'none',
        });
      return tl;
    },
  });

  export default {
    name: 'HomeView',
    components: {
      Container,
      Number,
      Legend,
    },
    data() {
      return {
        options: [
          { label: '日', value: 0 },
          { label: '月', value: 1 },
        ],
        weatherData: {
          loaded: false,
        },
        now: +new Date(),
        profitOverview: {
          loading: true,
          type: 0,
          dateSelect: [moment().subtract(7, 'day').valueOf(), moment().valueOf()],
          // 储能充放电总利润
          electric_benefit: 0,
          // 总充电电量KWH
          total_charge_quantity: '0.00',
          // 总充电成本
          total_charge_cost: '0.00',
          // 总放电电量
          total_discharge_quantity: '0.00',
          // 总放电收益
          total_discharge_benefit: '0.00',
          // PV总发电电量
          pv_discharge_quantity: '0.00',
          // PV总发电收益
          pv_discharge_benefit: '0.00',
        },
        // 光伏发电情况
        photovoltaicsStatus: {
          // 今天光伏发电量
          today_pv_discharge_quantity: 0,
          // 当月发电量
          month_pv_discharge_quantity: 0,
          // 总的光伏发电量
          total_pv_discharge_quantity: 0,
          // 累计碳减排量
          total_reduce_co2: 0,
        },
        // 当前年度减碳量
        totalReduceCarbon: {
          amount: 0,
          loading: true,
        },
        // 系统运行情况
        runTime: {
          hour: 0,
          day: 0,
        },
        peakValleyData: {
          active: 0,
          amount: 0,
        },
      };
    },
    async mounted() {
      this.peakValleyCharts = echarts.init(this.$refs.peakValley);
      setInterval(() => {
        this.now += 1000;
      }, 1 * 1000);

      this.initBable();
      await this.getWeather();
      await this.initChargeDischarge();
      await this.initPhotovoltaics();
      await this.initPeakValley();
      await this.getProfitOverview();
      await this.getRunTime();

      try {
        await this.getPhotovoltaicsStatus();
      } catch (error) {
        console.log('err', error);
      }

      // await this.initCarbonReduction();
    },
    computed: {
      dateStr() {
        const n = moment(this.now);
        return n.format('YYYY年MM月DD日') + ` 星期${dayEnum[n.day()]}`;
      },
      timeStr() {
        return moment(this.now).format('HH:mm:ss');
      },
    },
    methods: {
      initBable() {
        const delay = 6;
        gsap.timeline().bubble(this.$refs.bubble1, {
          range: 14,
          top: 100,
          delay: Math.random() * delay,
          duration: getRandomInt(4, 12),
        });

        gsap.timeline().bubble(this.$refs.bubble2, {
          range: 12,
          top: 70,
          delay: Math.random() * delay,
          duration: getRandomInt(4, 12),
        });

        gsap.timeline().bubble(this.$refs.bubble3, {
          range: 6,
          top: 80,
          opacity: 0.2,
          delay: Math.random() * delay,
          duration: getRandomInt(4, 12),
        });
        gsap.timeline().bubble(this.$refs.bubble4, {
          range: 10,
          top: 100,
          startOpacity: 0.9,
          opacity: 0.2,
          delay: Math.random() * delay,
          duration: getRandomInt(4, 12),
        });
        gsap.timeline().bubble(this.$refs.bubble5, {
          range: 12,
          top: 120,
          opacity: 0.1,
          delay: Math.random() * delay,
          duration: getRandomInt(4, 12),
        });
        gsap.timeline().bubble(this.$refs.bubble6, {
          range: 8,
          top: 110,
          startOpacity: 0.6,
          opacity: 0.1,
          delay: Math.random() * delay,
          duration: getRandomInt(4, 12),
        });
        gsap.timeline().bubble(this.$refs.bubble7, {
          range: 8,
          top: 104,
          opacity: 0,
          delay: Math.random() * delay,
          duration: getRandomInt(4, 12),
        });
      },
      async initPeakValley() {
        const { success, data } = await fetchBatteryProfit({
          deviceId: 'all',
          duration: 0,
          endDate: moment().subtract(1, 'day').format('X'),
          startDate: moment().subtract(7, 'day').startOf('day').format('X'),
        });

        if (success && data) {
          const { cost, total } = data;
          Object.assign(_cost, cost);
          Object.assign(_total, total);
          this.clickChargeDischarge();
        }
      },
      // 点击切换充电量和放电量
      clickChargeDischarge(active = 0) {
        this.peakValleyData.active = active;
        if (active === 0) {
          this.peakValleyData.amount = _total.total_charge_quantity;
        } else if (active === 1) {
          this.peakValleyData.amount = _total.total_discharge_quantity;
        }
        this.drawPeakValleyCharts();
      },
      drawPeakValleyCharts() {
        const xList = [];
        const tipList = [];
        const peakList = [];
        const flatList = [];
        const vallyList = [];
        for (const date in _cost) {
          const item = _cost[date];
          const {
            tip_charge_quantity,
            tip_discharge_quantity,
            peak_charge_quantity,
            peak_discharge_quantity,
            flat_charge_quantity,
            flat_discharge_quantity,
            vally_charge_quantity,
            vally_discharge_quantity,
          } = item;
          xList.push(moment(date * 1000).format('MM/DD'));
          tipList.push(
            this.peakValleyData.active === 0 ? tip_charge_quantity : tip_discharge_quantity
          );
          peakList.push(
            this.peakValleyData.active === 0 ? peak_charge_quantity : peak_discharge_quantity
          );
          flatList.push(
            this.peakValleyData.active === 0 ? flat_charge_quantity : flat_discharge_quantity
          );
          vallyList.push(
            this.peakValleyData.active === 0 ? vally_charge_quantity : vally_discharge_quantity
          );
        }

        this.peakValleyCharts.setOption({
          tooltip: {
            trigger: 'item',
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#8D9D9B',
            },
          },
          grid: {
            bottom: 30,
            left: 50,
          },
          yAxis: {
            type: 'value',
            name: 'KWH',
            nameTextStyle: {
              color: '#8D9D9B',
              align: 'right',
            },
            axisLabel: {
              color: '#8D9D9B',
            },
            splitLine: {
              lineStyle: {
                color: '#8D9D9B',
                type: 'dashed',
              },
            },
          },
          xAxis: {
            type: 'category',
            data: xList,
            axisLabel: {
              color: '#8D9D9B',
            },
            axisLine: {
              lineStyle: {
                color: '#8D9D9B',
              },
            },
          },
          color: ['#05AC68', '#05AC99', '#EEE1AD', '#F07036'],
          series: [
            {
              name: '谷',
              type: 'bar',
              stack: 'total',
              emphasis: {
                focus: 'series',
              },
              barMaxWidth: 20,
              data: vallyList,
            },
            {
              name: '平',
              type: 'bar',
              stack: 'total',
              emphasis: {
                focus: 'series',
              },
              barMaxWidth: 20,
              data: flatList,
            },
            {
              name: '峰',
              type: 'bar',
              stack: 'total',
              emphasis: {
                focus: 'series',
              },
              barMaxWidth: 20,
              data: peakList,
            },
            {
              name: '尖',
              type: 'bar',
              stack: 'total',
              emphasis: {
                focus: 'series',
              },
              barMaxWidth: 20,
              data: tipList,
            },
          ],
        });
      },
      async initChargeDischarge() {
        const { success, data } = await fetchChargeRate({
          deviceId: 'all',
          endDate: moment().format('X'),
          // period: 60,
          startDate: moment().subtract(1, 'day').startOf('hour').format('X'),
        });

        const xList = [];
        const list1 = [];
        const list2 = [];

        const chart = echarts.init(this.$refs.chargeDischargePower);

        if (success && data) {
          // 第几个设备的index
          let idx = 0;
          for (const deviceId in data) {
            const element = data[deviceId];
            const { ems_ac_active_power_neg, ems_ac_active_power_pos } = element;
            const len = ems_ac_active_power_neg.length;
            for (let i = 0; i < len; i++) {
              // 放电功能
              const discharge = ems_ac_active_power_pos[i];
              // 充电
              const charge = ems_ac_active_power_neg[i];

              if (idx === 0) {
                // 第一个设备，填充xList里面的数据
                xList.push(moment(discharge.time * 1000).format('HH:mm'));

                list1.push(charge.value);
                list2.push(discharge.value);
              } else {
                // 第二个设备往后，list1和list2数据分别累加charge和discharge数据

                list1[i] += charge.value;
                list2[i] += discharge.value;
              }
            }

            idx++;
          }
        }

        chart.setOption({
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            top: 30,
            bottom: 20,
            right: 20,
            left: 40,
          },
          xAxis: {
            type: 'category',
            data: xList,
            axisLabel: {
              color: '#8D9D9B',
            },
            axisLine: {
              lineStyle: {
                color: '#8D9D9B',
              },
            },
          },
          yAxis: {
            type: 'value',
            name: 'KW',
            nameTextStyle: {
              color: '#8D9D9B',
              align: 'right',
            },
            axisLabel: {
              color: '#8D9D9B',
            },
            splitLine: {
              lineStyle: {
                color: '#8D9D9B',
                type: 'dashed',
              },
            },
          },
          color: ['#05AC99', '#EEE1AD'],
          series: [
            {
              data: list2,
              type: 'line',
              name: '放电功率',
            },
            {
              data: list1,
              type: 'line',
              name: '充电功率',
            },
          ],
        });
      },
      async getRunTime() {
        const { success, data } = await fetchProjectList();
        if (success && data) {
          const { createTime = 0 } = data;

          const diffHour = moment().diff(moment(createTime * 1000), 'hour');
          const diffDay = moment().diff(moment(createTime * 1000), 'day');
          Object.assign(this.runTime, {
            hour: diffHour,
            day: diffDay,
          });
        }
      },
      async initCarbonReduction() {
        this.totalReduceCarbon.loading = true;
        const { success, data } = await fetchYearReduceCarbon({
          duration: 0,
          endDate: moment().format('X'),
          startDate: moment().startOf('year').format('X'),
        });
        this.totalReduceCarbon.loading = false;

        const labels = [];
        const dataList = [];
        if (success && data) {
          const { carbons } = data;
          let total = 0;
          carbons.map((item) => {
            const { time, carbonReduction } = item;
            const mon = moment(time * 1000).month() + 1;
            labels.push(`${mon}月`);
            dataList.push(carbonReduction);
            total += carbonReduction;
          });
          this.totalReduceCarbon.amount = total;
        }

        const chart = echarts.init(this.$refs.carbonReduction);

        chart.setOption({
          legend: {
            type: 'plain',
            right: 0,
            textStyle: {
              color: '#8D9D9B',
            },
          },
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            top: 40,
            bottom: 20,
          },
          xAxis: {
            type: 'category',
            data: labels,
            axisLabel: {
              color: '#8D9D9B',
            },
            axisLine: {
              lineStyle: {
                color: '#8D9D9B',
              },
            },
          },
          yAxis: {
            type: 'value',
            name: 'kgCO2',
            nameTextStyle: {
              color: '#8D9D9B',
              align: 'right',
            },
            axisLabel: {
              color: '#8D9D9B',
            },
            splitLine: {
              lineStyle: {
                color: '#8D9D9B',
                type: 'dashed',
              },
            },
          },
          color: ['#05AC99', '#EEE1AD'],
          series: [
            {
              name: '减碳量',
              type: 'bar',
              data: dataList,
            },
          ],
        });
      },
      async getWeather() {
        const { success, data } = await fetchWeather();
        if (success && data) {
          this.weatherData.loaded = true;

          for (let key in data) {
            this.$set(this.weatherData, key, data[key]);
          }
        }
      },
      async getPhotovoltaicsStatus() {
        const { success, data } = await fetchTotalProfit();
        if (success && data) {
          const { today, total } = data;

          Object.assign(this.photovoltaicsStatus, {
            today_pv_discharge_quantity: parseFloat(today.pv_discharge_quantity),
            total_pv_discharge_quantity: Math.floor(parseFloat(total.pv_discharge_quantity)),
          });
        }

        const { success: success1, data: data1 } = await fetchReportMonth({
          duration: 0,
          endDate: moment().format('X'),
          startDate: moment().startOf('M').format('X'),
        });
        if (success1 && data1) {
          const { total_pv } = data1;
          this.photovoltaicsStatus.month_pv_discharge_quantity = parseFloat(total_pv);
        }

        const { success: success2, data: data2 } = await fetchCarbonRealtime({
          duration: 0,
          endDate: moment().format('X'),
          startDate: moment().subtract(1, 'day').format('X'),
        });
        if (success2 && data2) {
          const { total_reduction } = data2;
          this.photovoltaicsStatus.total_reduce_co2 = Math.floor(total_reduction);
        }
      },
      async getProfitOverview() {
        let startDate = 0,
          endDate = 0;
        if (this.profitOverview.type === 0) {
          if (isArray(this.profitOverview.dateSelect)) {
            startDate = moment(this.profitOverview.dateSelect[0]).startOf('day').format('X');
            endDate = moment(this.profitOverview.dateSelect[1]).endOf('day').format('X');
          }
        } else if (this.profitOverview.type === 1) {
          if (isNumber(this.profitOverview.dateSelect)) {
            startDate = moment(this.profitOverview.dateSelect).startOf('month').format('X');
            endDate = moment(this.profitOverview.dateSelect).endOf('month').format('X');
          }
        }
        this.profitOverview.loading = true;
        const { success, data } = await fetchOperateProfit({
          deviceId: '',
          duration: 0,
          endDate,
          startDate,
        });
        this.profitOverview.loading = false;
        if (success && data) {
          const {
            electric_benefit,
            total_charge_quantity,
            total_discharge_quantity,
            total_charge_cost,
            pv_discharge_quantity,
            total_discharge_benefit,
            pv_discharge_benefit,
            total_benefit_extend,
          } = data;
          Object.assign(this.profitOverview, {
            electric_benefit: parseFloat(total_benefit_extend),
            total_charge_quantity,
            total_discharge_quantity,
            total_charge_cost,
            pv_discharge_quantity,
            total_discharge_benefit,
            pv_discharge_benefit,
          });
        }
      },
      // 光伏发电曲线
      async initPhotovoltaics() {
        const { success, data } = await fetchPVRate({
          endDate: moment().format('X'),
          groupId: 'all',
          itemId: 'all',
          // period: 60,
          startDate: moment().subtract(1, 'day').startOf('hour').format('X'),
        });

        const xList = [];
        const list1 = [];

        const chart = echarts.init(this.$refs.photovoltaics);

        if (success && data && data.length) {
          data.map((item) => {
            const { value, time } = item;
            xList.push(moment(time * 1000).format('HH:mm'));
            list1.push(value);
          });
        }

        chart.setOption({
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            top: 30,
            bottom: 20,
            right: '5%',
          },
          xAxis: {
            type: 'category',
            data: xList,
            axisLabel: {
              color: '#8D9D9B',
            },
            axisLine: {
              lineStyle: {
                color: '#8D9D9B',
              },
            },
          },
          yAxis: {
            type: 'value',
            name: 'KW',
            nameTextStyle: {
              color: '#8D9D9B',
              align: 'right',
            },
            axisLabel: {
              color: '#8D9D9B',
            },
            splitLine: {
              lineStyle: {
                color: '#8D9D9B',
                type: 'dashed',
              },
            },
          },
          color: ['#05AC99', '#EEE1AD', '#F07036'],
          series: [
            {
              data: list1,
              type: 'line',
              name: '光伏发电',
            },
          ],
        });
      },
      keepDecimal(num) {
        try {
          const temp = parseFloat(num);
          return temp.toFixed(2);
        } catch (error) {
          return '0';
        }
      },
      // 更改年月日类型
      changeDatePickerType() {
        this.profitOverview.dateSelect = null;
      },
      async changeDatePicker() {
        await this.getProfitOverview();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './index.scss';
</style>

<style lang="scss">
  .home_box {
    .el-loading-mask {
      background-color: transparent;
    }
    .type-select {
      &:hover {
        .el-input__inner {
          border: 1px solid #32d2c1;
        }
      }
      .el-input__inner {
        background-color: rgba($color: #ffffff, $alpha: 0.1);
        border: 1px solid #0000;
        color: rgba($color: #ffffff, $alpha: 0.9);
        &:active {
          border: 1px solid #32d2c1;
        }
      }
    }

    .date-select {
      background-color: rgba($color: #ffffff, $alpha: 0.1);
      border: 1px solid #0000;
      color: rgba($color: #ffffff, $alpha: 0.9);
      .el-range-input {
        background-color: transparent;
        color: rgba($color: #ffffff, $alpha: 0.9);
      }
      .el-input__inner {
        background-color: rgba($color: #ffffff, $alpha: 0.1);
        border: 1px solid #0000;
        color: rgba($color: #ffffff, $alpha: 0.9);
        &:active {
          border: 1px solid #32d2c1;
        }
      }
      .el-input__icon {
        display: none;
      }
    }
  }
</style>
