import request from "@/utils/request";

// 整站模式
export function fetchRunStatus(data) {
  return request({
    url: "/project/site",
    method: "POST",
    data,
  });
}

// 运营收益查询
export function fetchOperateProfit(data) {
  return request({
    url: "/operation/getProfit",
    method: "POST",
    data,
  });
}

// 三⻚收益查询
export function fetchTotalProfit(data) {
  return request({
    url: "/operation/getTotalProfit",
    method: "POST",
    data,
  });
}

// 获取项⽬列表
export function fetchProjectList(data) {
  return request({
    url: "/project/list",
    method: "POST",
    data,
  });
}

// 实时排放
export function fetchCarbonRealtime(data) {
  return request({
    url: "/carbon/realtime",
    method: "POST",
    data,
  });
}

// pv功率
export function fetchPVPower(data) {
  return request({
    url: "/heatMap/pvDischarge",
    method: "POST",
    data,
  });
}

// pv功率曲线
export function fetchPVRate(data) {
  return request({
    url: "/diagram/getPvRate",
    method: "POST",
    data,
  });
}

// 年度减碳
export function fetchYearReduceCarbon(data) {
  return request({
    url: "/carbon/year",
    method: "POST",
    data,
    timeout: 0,
  });
}

// 月报
export function fetchReportMonth(data) {
  return request({
    url: "/report/month",
    method: "POST",
    data,
    timeout: 0,
  });
}

// 充放电曲线查询
export function fetchChargeRate(data) {
  return request({
    url: "/diagram/getChargeRate",
    method: "POST",
    data,
  });
}

// 充放电曲线单线
export function fetchChargeOneRate(data) {
  return request({
    url: "/diagram/getChargeOneRate",
    method: "POST",
    data,
  });
}

// 充放电收益查询
export function fetchBatteryProfit(data) {
  return request({
    url: "/operation/getBatteryProfit",
    method: "POST",
    data,
  });
}

// 实时天气
export function fetchWeather(data) {
  return request({
    url: "/weather/getRealtime",
    method: "POST",
    data,
  });
}
