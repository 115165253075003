<template>
  <div class="number-box flex">
    <template v-for="(item, index) in numberVal">
      <div class="number-box-item" :key="index">
        <div class="comma" v-if="item === ','">,</div>
        <div class="number-item" v-else>{{ item }}</div>
      </div>
    </template>
  </div>
</template>
<script>
  import { splitComma } from '@/utils';
  export default {
    name: 'Number',
    props: {
      value: {
        type: Number || String,
        default: '',
      },
    },
    data() {
      return {
        numberVal: '0',
      };
    },
    mounted() {
      this.numberVal = splitComma(this.value, false);
    },
    watch: {
      value(newVal) {
        this.numberVal = splitComma(newVal, false);
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import './index.scss';
</style>
