import { storageEncrypt } from "@boe/utils-es";

export { isDom, getRandomInt, isNumber, isArray } from "@boe/utils-es";

const { storageSet, storageGet, storageRemove } = storageEncrypt("jingdian_");

export { storageSet, storageGet, storageRemove };

/**
 * 将数值用逗号分隔开
 * @param {String|Number} num 传入的数值
 * @param {Boolean} flag 是否保留小数点
 * @return {String}
 */
export function splitComma(num, flag = true) {
  if (num === "" || num === 0 || !num) {
    return "0";
  }
  const num_str = num + "";

  // 小数点所在字符串的位置
  const decimalPointIndex = num_str.indexOf(".");
  // 小数部分的字符串
  let decimalStr = "";
  // 整数部分的字符串
  let integerStr = "";

  if (decimalPointIndex !== -1) {
    // 有小数点
    if (flag) {
      decimalStr = num_str.substring(decimalPointIndex);
    }
    integerStr = num_str.substring(0, decimalPointIndex);
  } else {
    integerStr = num_str;
  }

  let list = integerStr.split("");
  if (list.length >= 4) {
    const out = [];
    // 原来数组进行反转
    const _rv = list.reverse();
    //反转后，每隔3位插入一个逗号
    for (let i = 0; i < _rv.length; i++) {
      out.push(_rv[i]);

      if ((i + 1) % 3 === 0) {
        out.push(",");
      }
    }
    // 列表再次反转回来
    list = out.reverse();
    // 有一定的几率，逗号在第一位，需要去掉
    if (list.length && list[0] === ",") {
      list.shift();
    }
  }
  return list.join("") + (flag ? decimalStr : "");
}
