import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/home/index.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.user.hasToken) {
    try {
      await store.dispatch("user/getUserToken");
    } catch (error) {
      console.log("err", error);
    }
  }

  next();
});

export default router;
