import request from "@/utils/request";

// ⽤户授权登录
export function fetchToken(data) {
  return request({
    url: "/oauth/token",
    method: "POST",
    data,
  });
}

// 获取当前⽤户信息
export function fetchUserInfo(data) {
  return request({
    url: "/user/info",
    method: "GET",
    data,
  });
}
