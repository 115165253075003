<template>
  <div class="container_box">
    <div class="header flex">
      <div class="header_1"></div>
      <div class="header_title">
        <div class="title_cont">{{ title }}</div>
        <div class="title_cont_abs">{{ title }}</div>
      </div>
      <div class="header_3"></div>
      <div class="header_4"></div>
      <div class="header_5"></div>
    </div>
    <div class="content">
      <div class="content_left"></div>
      <div class="content_box">
        <slot></slot>
      </div>
      <div class="content_right"></div>
    </div>
    <div class="footer">
      <div class="footer_left"></div>
      <div class="footer_center"></div>
      <div class="footer_right"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Container",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
