import axios from 'axios';
import store from '@/store';

const instance = axios.create({
  baseURL: 'https://pangu-api.weiheng-tech.com/api',
  timeout: 60 * 1000,
});

instance.interceptors.request.use((config) => {
  const { url } = config;

  if (url !== '/oauth/token') {
    // 非登陆接口才会加
    Object.assign(config.headers, {
      Authorization: store.state.user.token,
      // Projectid: 'f4477a16be694375b2e9778d2862c217',
      // 2023/11/10 改动id
      Projectid: '8721f6f53b954e75a38ed08a0c288cc1',
    });
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    const { data, headers } = response;

    const contentType = headers['content-type'] || headers['Content-Type'];
    if (contentType.indexOf('application/json') !== -1) {
      console.log(data, 'data');
      const { code } = data;
      if (code === '401 UNAUTHORIZED') {
        // 接口授权错误，删除token
        store.commit('user/removeToken');
        window.location.reload();
      } else if (code === 'ILLEGAL_ACCESS') {
        // 接口授权错误，删除token
        store.commit('user/removeToken');
        window.location.reload();
      }
      return data;
    }
  },
  (error) => {}
);

export default instance;
