<template>
  <div :class="['legend-box', 'flex', 'items-center', classes]">
    <div class="icon">
      <div class="circle" :style="`background: ${color}`"></div>
      <div class="line" :style="`background: ${color}`"></div>
    </div>
    <div class="title">{{ title }}</div>
  </div>
</template>
<script>
  export default {
    name: 'Legend',
    props: {
      title: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: '#fff',
      },
      classes: {
        type: String,
        default: '',
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import './index.scss';
</style>
