import { fetchToken } from "@/api/user";
import { storageGet, storageSet } from "@/utils";

const TOKEN_NAME = "token";

const storeToken = storageGet(TOKEN_NAME);
console.log("storeToken", storeToken);

export default {
  namespaced: true,
  state: {
    token: storeToken,
    hasToken: !!storeToken,
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
      state.hasToken = true;
      storageSet(TOKEN_NAME, payload);
    },
    removeToken(state) {
      state.token = "";
      state.hasToken = false;
      storageSet(TOKEN_NAME, "");
    },
  },

  actions: {
    async getUserToken({ commit }) {
      const { success, data } = await fetchToken({
        username: "jdfapi",
        password: "jdf123456",
        code: "",
        phone: "",
        type: 0,
      });

      if (success && data) {
        const { access_token } = data;
        commit("setToken", access_token);
      }
    },
  },
};
